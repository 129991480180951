import { FeatureFlags } from '../constants/featureFlags';

import useFeatureFlag from './useFeatureFlag';

export default function useGoogleSSOFlags() {
  const googleLoginEnabled = useFeatureFlag(FeatureFlags.GOOGLE_LOGIN, true);
  const googleSignUpEnabled = useFeatureFlag(FeatureFlags.GOOGLE_SIGNUP, true);

  return {
    googleLoginEnabled,
    googleSignUpEnabled,
  };
}
