import { useState } from 'react';
import classNames from 'classnames';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { useImmer } from 'use-immer';

import { ReactComponent as ArrowLeft } from '../../assets/icons/koi/arrow-left.svg';
import { ReactComponent as User } from '../../assets/icons/koi/cathy-user.svg';
import { ReactComponent as EyeIcon } from '../../assets/icons/koi/eye-icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/koi/paywall-close-icon.svg';
import { ReactComponent as ProfileIcon } from '../../assets/icons/koi/paywall-profile-icon.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/koi/paywall-warning-icon.svg';
import SSOButtons from '../../common/SSOButtons/SSOButtons';
import Testimonial from '../../common/Testimonial/Testimonial';
import TruepathInput from '../../common/TruepathInput/TruepathInput';
import useGoogleSSOFlags from '../../hooks/useGoogleSSOFlags';
import useGTM from '../../hooks/useGTM';
import usePricingExperimentFlag from '../../hooks/usePricingExperimentFlag';
import { useWindowSize } from '../../hooks/useWindowSize';
import KoiAlert from '../../layout/koi/KoiAlert';
import KoiButton from '../../layout/koi/KoiButton';
import KoiTitle, { Highlight } from '../../layout/koi/KoiTitle';
import { useAppContext } from '../../store/AppContext';
import { api } from '../../utils/api';
import { ENV_TEST, getEnvSetting } from '../../utils/envSettings';
import Tracking from '../../utils/tracking';
import TrackingV2 from '../../utils/trackingV2';
import { setUserForProviders } from '../../utils/user';
import { getLaunchDarklySessionKey } from '../../utils/util';

export function SignUpDescription({ onBack, isPYG }) {
  const { PRICING_EXPERIMENT_ENABLED } = usePricingExperimentFlag();
  return (
    <>
      <div className="top-content">
        <div className="arrow-icon-container" onClick={onBack}>
          <ArrowLeft />
        </div>
        <ProfileIcon className="profile-icon" />
        {PRICING_EXPERIMENT_ENABLED && !isPYG ? (
          <KoiTitle>Create an account to start your 7-day free trial.</KoiTitle>
        ) : (
          <KoiTitle>
            <Highlight whiteHighlight> Create an account </Highlight> to unlock
            your resume.
          </KoiTitle>
        )}
      </div>
      <div className="footer-info">
        <Testimonial
          details="11+ years of experience"
          quote="I’m so impressed by me because of this site!"
          userImg={<User />}
          userName="Cathy C."
        />
      </div>
    </>
  );
}

const apiClientId = getEnvSetting('API_CLIENT_ID');
const disableRecaptcha = getEnvSetting('DISABLE_RECAPTCHA');

export function AccountCreation({ onContinue, onHide }) {
  const { appState, appDispatch } = useAppContext();
  const { accessToken, currentUser } = appState;
  const { isMobile } = useWindowSize();
  const { PRICING_EXPERIMENT_ENABLED } = usePricingExperimentFlag();
  const { googleSignUpEnabled } = useGoogleSSOFlags();
  const isProfileFlow = currentUser?.onboarding_flow === 'profile_builder';

  const [signingUp, setSigningUp] = useState(false);
  const [ssoError, setSsoError] = useState('');
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [formData, setFormData] = useImmer({});
  const [revealPassword, setRevealPassword] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const ldClient = useLDClient();

  const { trackGTMEvent } = useGTM();

  const processAccountSuccess = async (accessToken, provider = null) => {
    await appDispatch({ type: 'accessToken/set', payload: accessToken });

    const { data: userData } = await api.get('/users/me', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    await appDispatch({ type: 'currentUser/set', payload: userData });

    setUserForProviders(userData, ldClient, true, 'AccountScreen');
    Tracking.trackSignedUp();
    Tracking.trackUserClicked({ widget_name: 'Create account' });
    TrackingV2.trackUserClicked({ widget_name: 'Create account' });

    Tracking.trackUserClicked({
      widget_name: 'Sign Up',
      user_flow: currentUser?.onboarding_flow,
      options: provider === 'google_oauth2' ? 'Sign up with Google' : provider,
    });

    trackGTMEvent('createAccount', {
      currentUser: {
        name: userData.user_name,
        email: userData.email,
      },
    });

    if (provider === 'google_oauth2') {
      Tracking.trackUserClicked({ widget_name: 'Sign Up with Google' });
      TrackingV2.trackUserClicked({ widget_name: 'Sign Up with Google' });
    }

    onContinue();
  };

  const omniauthSignUp = async (provider, code) => {
    setError('');
    setSsoError('');

    try {
      setSigningUp(true);

      const url = `/auth/${provider}/callback`;

      if (currentUser?.id) {
        await api.put(
          url,
          {
            code,
            id: currentUser.id,
          },
          { withCredentials: true },
        );

        // Update onboarding step for user
        await api.put(`users/${currentUser.id}`, {
          user: { onboarding_step: 'dashboard' },
        });
        if (isProfileFlow) {
          Tracking?.trackUserProfileData({
            $profile_generated: true,
          });
        }

        await processAccountSuccess(accessToken, provider);
      } else {
        const { data: session } = await api.post(
          url,
          {
            client_id: apiClientId,
            code,
          },
          { withCredentials: true },
        );

        // Update onboarding step for user
        await api.put(`users/${currentUser.id}`, {
          user: { onboarding_step: 'dashboard' },
        });
        if (isProfileFlow) {
          Tracking?.trackUserProfileData({
            $profile_generated: true,
          });
        }

        await processAccountSuccess(session.access_token, provider);
      }
    } catch (error) {
      ssoFailure(provider);
    } finally {
      setSigningUp(false);
    }
  };

  const signUpWithGoogle = (response) => {
    omniauthSignUp('google_oauth2', response.code);
  };

  const ssoFailure = (provider) => {
    setSsoError('Google');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((draft) => {
      draft[name] = value;
    });
  };

  const handleFocus = (e) => {
    const { name } = e.target;

    setError('');
    if (name === 'email') {
      setEmailError('');
    } else if (name === 'password') {
      setInvalidPassword(false);
    }
  };

  const handleSubmit = async (e, automaticRetry = false) => {
    e.preventDefault();
    const newUserWithSameDevice = automaticRetry;

    setEmailError('');
    setInvalidPassword(false);
    setError('');

    const form = e.currentTarget;
    const isValid = newUserWithSameDevice || form.checkValidity();

    setValidated(true);

    if (!isValid) {
      return;
    }

    if (!formData.password || !formData.email) {
      setError('Oops! You must create an account before continuing.');
      return;
    }

    if (formData.password?.length < 6 || formData.password?.length > 20) {
      setInvalidPassword(true);
      return;
    }

    try {
      setSigningUp(true);

      if (currentUser?.id) {
        const utm_source =
          localStorage.getItem('__msc_utm_source') || undefined;

        const payload = {
          user: {
            email: formData.email,
            password: formData.password,
            name: formData.name,
            onboarding_step: 'dashboard',
            utm_source,
          },
        };
        if (isProfileFlow) {
          Tracking?.trackUserProfileData({
            $profile_generated: true,
          });
        }
        await api.put(`/users/${currentUser.id}`, payload);
        await processAccountSuccess(accessToken, 'Account Created');
      } else {
        const LD_CONTEXT_KEY = getLaunchDarklySessionKey(null, automaticRetry);

        const payload = {
          client_id: apiClientId,
          registration: {
            email: formData.email,
            password: formData.password,
            name: formData.name,
            mtr: LD_CONTEXT_KEY,
            onboarding_step: 'dashboard',
          },
        };
        if (isProfileFlow) {
          Tracking?.trackUserProfileData({
            $profile_generated: true,
          });
        }

        // If it is not Running Integration test verify captcha
        if (!ENV_TEST && disableRecaptcha !== 'true') {
          // payload.captcha_token = await recaptchaRef.current.executeAsync();
        } else {
          // Skip captcha for Integration Tests
          payload.registration.mocked = true;
        }

        const { data: session } = await api.post('/registrations', payload, {
          withCredentials: true,
        });

        await processAccountSuccess(session.access_token, 'Account Created');
      }
    } catch (error) {
      const errors = error?.response?.data?.errors;

      if (errors?.email) {
        switch (errors.email[0]) {
          case 'has already been taken':
            setEmailError('This email address is already taken.');
            break;

          case 'is invalid':
          default:
            setEmailError('The email address you entered is invalid.');
        }
      }

      if (errors?.mtr && !automaticRetry) {
        handleSubmit(e, true);
      }

      setError('Unable to process your request. Please try again.');
    } finally {
      setSigningUp(false);
    }
  };

  return (
    <>
      <div className="top-content">
        <div
          className="close-icon"
          onClick={() => {
            onHide();
          }}
        >
          <CloseIcon />
        </div>
        {(ssoError || error) && (
          <KoiAlert className="error-alert">
            <WarningIcon />
            <div>
              {ssoError ? (
                <>
                  Oops! There was an issue signing up with {ssoError}. If you
                  already signed up, try to <Link to="/login">log in</Link>{' '}
                  first.
                </>
              ) : (
                error && <>{error}</>
              )}
            </div>
          </KoiAlert>
        )}
        <div className={classNames('sign-up', { error: ssoError || error })}>
          {googleSignUpEnabled && (
            <div className="sso-btns">
              <SSOButtons
                googleButtonText="Sign up with Google"
                disabled={signingUp}
                onSuccessGoogle={signUpWithGoogle}
                onFailure={ssoFailure}
              />
            </div>
          )}

          <Form
            id="sign-up-form-v2"
            noValidate
            className="sign-up-form"
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Form.Group className="form-item">
              <TruepathInput
                type="email"
                label="Email"
                name="email"
                datacy="email"
                isInvalid={!!emailError}
                errorMessage={emailError || 'Please enter your email.'}
                value={formData?.email}
                onChange={handleChange}
                onFocus={handleFocus}
              />
            </Form.Group>

            <Form.Group className="form-item">
              <TruepathInput
                type={revealPassword ? 'text' : 'password'}
                label="Password"
                name="password"
                datacy="password"
                isInvalid={invalidPassword}
                errorMessage={
                  invalidPassword
                    ? 'Must be between 6 and 20 characters.'
                    : 'Please enter your password.'
                }
                value={formData?.password}
                onChange={handleChange}
                onFocus={handleFocus}
              />
              <EyeIcon
                className="eye-icon"
                onClick={() => setRevealPassword(!revealPassword)}
              />
            </Form.Group>

            <div
              className={
                PRICING_EXPERIMENT_ENABLED && isMobile
                  ? 'continue-btn-wrapper-variant'
                  : 'continue-btn-wrapper'
              }
            >
              <KoiButton
                className={
                  PRICING_EXPERIMENT_ENABLED && isMobile
                    ? 'continue-btn-variant'
                    : 'continue-btn'
                }
                data-cy="continue-btn"
                disabled={signingUp || !!error}
                variant="primary"
                type="submit"
              >
                Continue to billing
              </KoiButton>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
