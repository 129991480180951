import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';

import Bugsnag from '@bugsnag/js';

import { api } from './api';
import Tracking from './tracking';
import { getLaunchDarklySessionKey } from './util';

export async function updateLatestUserData(dispatch) {
  if (!dispatch) {
    Bugsnag.notify(new Error('Dispatch must be defined'));
    return;
  }

  try {
    const { data } = await api.get('/users/me');
    await dispatch({ type: 'currentUser/set', payload: data });
  } catch (error) {
    Bugsnag.notify(
      new Error(
        `Error updating user data at updateLatestUserData: ${error?.message}`,
      ),
    );
  }
}

export async function setUserForProviders(
  user,
  ldClient,
  isNewUser = false,
  parentCaller = '',
) {
  if (!user) {
    console.error('User must be defined');
    return;
  }

  if (ldClient) {
    await ldClient.waitUntilReady();
    const LD_CONTEXT_KEY = getLaunchDarklySessionKey(user);

    // Set user context in Launch Darkly
    const context = {
      kind: 'user',
      key: LD_CONTEXT_KEY,
      name: user.user_name,
      userId: user.id,
      email: user.email,
      _meta: { privateAttributes: ['userId', 'email'] },
    };

    ldClient?.identify(context, null, (error) => {
      if (error) {
        Bugsnag.notify(error);
      }
    });
  } else {
    Bugsnag.notify(
      new Error(
        `LaunchDarkly client not initiliazed at setUserForProviders, called from ${parentCaller} `,
      ),
    );
  }

  // Set user in mixpanel

  let userProperties = {
    $name: user.user_name,
    $email: user.email,
    $user_id: user.id,
    $user_mtr: user.mtr,
    $ld_context_key_cookie: Cookies.get('LD_CONTEXT_KEY'),
  };

  if (user.vanity_url) {
    const { data } = await api.get(`/users/profile/${user.vanity_url}`);
    userProperties = {
      ...userProperties,
      $public_status: data.profile_visible ? 'Public' : 'Private',
    };
  }
  if (isNewUser) {
    userProperties.$created = new Date().toISOString();
  }
  Tracking?.trackUserProfileData(userProperties);
  Tracking?.identify(user.id);

  // Set user in Bugsnag
  Bugsnag?.setUser(user.id, user.email, user.user_name);

  // Set user in Clarity
  window.clarity?.('identify', user.email);
  window.clarity?.('set', 'userEmail', user.email);
}

export const getUserExperimentProperties = (currentUser) => {
  const STORED_LD_CONTEXT_KEY = Cookies.get('LD_CONTEXT_KEY');
  const multiple_accounts_on_same_device =
    Cookies.get('multiple_accounts_on_same_device') || 'false';
  const newDevice = currentUser?.mtr !== STORED_LD_CONTEXT_KEY;
  const userIdentified = currentUser?.id;
  const userProperties = {
    user_id: currentUser?.id || 'No user',
    user_mtr: currentUser?.mtr || 'No user',
    ld_context_key_cookie: STORED_LD_CONTEXT_KEY,
    from_view: window.location.pathname,
    new_device: newDevice,
    multiple_accounts_on_same_device,
    userIdentified,
  };

  return userProperties;
};
// Utility function to check if user can create documents
export function canCreateDocuments(currentUser, tokensNeeded = 1) {
  const { subscribed, subscription, tokens } = currentUser;

  // If user has enough tokens left
  // CAN create documents.
  if (tokens >= tokensNeeded) {
    return true;
  }

  // If the user is subscribed and the subscription has unlimited tokens
  // CAN create documents.
  const unlimitedTokens = subscription?.product.unlimited_tokens;
  if (subscribed && unlimitedTokens) {
    return true;
  }

  // If user is not subscribed, doesn't have a subscription with unlimited tokens, or has no tokens left
  // CAN NOT create documents.
  return false;
}

export function userHasBeenSubscribed(user) {
  return user?.subscribed || user?.unsubscribed;
}

export async function updateUserResumeStep(
  userId,
  step,
  view,
  onboardingStep = null,
) {
  if (!userId) {
    throw new Error('userId is required');
  }
  try {
    const payload = {
      user: {
        resume_builder_step: step,
        resume_builder_view: view,
      },
    };

    if (onboardingStep) {
      payload.user.onboarding_step = onboardingStep;
    }

    const { data } = await api.put(`/users/${userId}`, payload);
    return data;
  } catch (error) {
    Bugsnag.notify(
      new Error(
        `Error updating user current resume builder step: ${error?.message} at step ${step} and view: ${view}`,
      ),
    );
    console.error('Error updating user current resume builder step', error);
  }
}

export function userHasCoreData(data) {
  return (
    !isEmpty(data?.contact_details_record) ||
    !isEmpty(data?.professional_experiences) ||
    !isEmpty(data?.educations) ||
    !isEmpty(data?.user_skills)
  );
}

export const AVAILABILITY_STATUS_MAP = {
  actively_seeking: 'Actively seeking',
  passively_considering: 'Passively considering',
  'N/A': 'N/A',
};
