import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Bugsnag from '@bugsnag/js';

import usePaywallMonthDiscount from '../hooks/usePaywallMonthDiscount';
import { useAppContext } from '../store/AppContext';
import { api } from '../utils/api';

export default function useOnboarding(pageStep, options = {}) {
  const { appState } = useAppContext();
  const [searchParams] = useSearchParams();
  const { currentUser, loggedIn } = appState;
  const navigate = useNavigate();

  const [ready, setReady] = useState(false);
  const [redirecting, setRedirecting] = useState(false);

  const {
    utmSource,
    utmMedium,
    utmContent,
    utmStripeId,
    showPaywallMonthDiscount,
  } = usePaywallMonthDiscount();

  const handleNavigateToDownloadPage = async () => {
    try {
      const { data } = await api.get('/job_opportunities');
      const lastJobOpportunity = data.length ? data[0] : {};
      const resumeId = lastJobOpportunity?.resume?.id;
      if (resumeId) {
        let url = `/view-resume/${resumeId}`;
        if (showPaywallMonthDiscount) {
          url = `/view-resume/${resumeId}?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_content=${utmContent}&utm_stripe_id=${utmStripeId}`;
        }
        return navigate(url);
      } else {
        return navigate('/resume-builder', { replace: true });
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  };

  const handleOnboardedUser = (onboarding_flow) => {
    if (searchParams.get('return_to_pricing')) {
      return navigate('/plans', { replace: true });
    }

    const navigateTo =
      onboarding_flow === 'profile_builder' ? '/profile' : '/dashboard';

    return navigate(navigateTo, { replace: true });
  };

  // Check the user onboarding step and redirect to the appropriate page
  useEffect(() => {
    const checkPaths = () => {
      // if the resource is ready, do nothing
      if (ready) {
        return;
      }

      // if user is not logged in, the resource is ready to be rendered
      if (loggedIn === false) {
        setReady(true);

        if (options.skipRedirect) {
          return;
        }

        // If the page is the base page (my.truepath.ai), users should redirect to login page if they are not logged in
        pageStep === 'landing_page' && navigate('/login');
        return;
      }

      // If user is logged in and the user data if fetched, check the onboarding step of the user
      if (currentUser) {
        const {
          onboarding_step,
          onboarding_flow,
          resume_builder_step,
          resume_builder_view,
          resume_template_id,
        } = currentUser;

        setReady(true);

        // if the redirect is skipped, do nothing
        if (options.skipRedirect) {
          return;
        }

        // If the page step is the same as the user onboarding step
        if (pageStep === onboarding_step) {
          // If the user is on the resume builder step, redirect to the correct builder page
          if (onboarding_step === 'resume_builder') {
            const navigateTo =
              onboarding_flow === 'resume_builder'
                ? '/resume-builder'
                : '/profile-builder';

            return navigate(navigateTo, { replace: true });
          }

          // Do nothing
          return;
        }

        setRedirecting(true);

        if (onboarding_step === 'resume_builder') {
          // For legacy users without the onboarding_step: 'download_resume'
          if (
            resume_builder_step === 'review_step' &&
            resume_builder_view === 'CHOOSE_TEMPLATE' &&
            resume_template_id
          ) {
            return handleNavigateToDownloadPage();
          }

          // For profile builder check if the step is correct
          if (onboarding_flow === 'profile_builder') {
            if (resume_builder_view === 'CHOOSE_TEMPLATE') {
              return handleNavigateToDownloadPage();
            }
          }

          // Navigate to the correct builder
          const navigateTo =
            onboarding_flow === 'resume_builder'
              ? '/resume-builder'
              : '/profile-builder';

          return navigate(navigateTo, { replace: true });
        }

        if (onboarding_step === 'download_resume') {
          return handleNavigateToDownloadPage();
        }

        if (onboarding_step === 'dashboard') {
          handleOnboardedUser(onboarding_flow);
        }
      }
    };

    checkPaths();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, loggedIn, ready, pageStep, navigate, options]);

  return { ready, redirecting };
}
