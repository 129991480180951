import './PurchaseModalV2.scss';

import Modal from 'react-bootstrap/Modal';

import PaywallV2 from '../../routes/Paywall/PaywallV2';

export default function PurchaseModalV2({
  show,
  onHide,
  initialScreen,
  ftue = false,
  showPromoCode,
}) {
  return (
    <Modal className="__purchase-modal-v2" centered show={show} onHide={onHide}>
      <Modal.Body>
        <PaywallV2
          onHide={onHide}
          ftue={ftue}
          initialScreen={initialScreen}
          showPromoCode={showPromoCode}
        />
      </Modal.Body>
    </Modal>
  );
}
