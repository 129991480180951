import { FeatureFlags } from '../constants/featureFlags';
import useFeatureFlag from '../hooks/useFeatureFlag';

export default function usePricingExperimentFlag() {
  const enabled = useFeatureFlag(FeatureFlags.PRICING_EXPERIMENT, 'Control');

  return {
    PRICING_EXPERIMENT_ENABLED: enabled?.toLowerCase()?.includes('treatment'),
    variant: enabled,
  };
}
