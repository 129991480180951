import { useEffect, useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { ReactComponent as ClipboardGraphIcon } from '../../assets/icons/koi/clipboard-graph-icon.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/koi/home-icon.svg';
import { ReactComponent as HomeIconOutline } from '../../assets/icons/koi/home-icon-outline.svg';
import TrustPilot from '../../common/TrustPilot/TrustPilot';
import { FeatureFlags } from '../../constants/featureFlags';
import useFeatureFlag from '../../hooks/useFeatureFlag';
import usePricingExperimentFlag from '../../hooks/usePricingExperimentFlag';
import useTrueprofileEnabled from '../../hooks/useTrueprofileEnabled';
import CreateDocumentButton from '../../routes/Dashboard/CreateDocumentButton';
import SplashScreen from '../../routes/Trueprofile/SplashScreen';
import Tracking from '../../utils/tracking';
import { staticPage } from '../../utils/util';

import TrudyLogo from './TrudyLogo';

import './MobileHeader.scss';

export default function MobileHeader({
  children,
  currentUser,
  superUser,
  checkPagePath = {},
  checkIsSelectedPath,
  onForceLogin,
}) {
  const [openSplashScreen, setOpenSplashScreen] = useState(false);
  const { PRICING_EXPERIMENT_ENABLED } = usePricingExperimentFlag();

  const {
    isAdminPage,
    isFTUEPreviewPage,
    isProfileBuilderPage,
    isPublicProfilePage,
    isResumeBuilderPage,
  } = checkPagePath;

  const showReferralUI = useFeatureFlag(FeatureFlags.V_1_3_REFERRAL_UI, false);
  const fakeDoorEnabled = useFeatureFlag(FeatureFlags.FAKE_DOOR, false);
  const trueprofileEnabled = useTrueprofileEnabled();
  const hideLinks = isFTUEPreviewPage && PRICING_EXPERIMENT_ENABLED;
  const showTrustPilot = useFeatureFlag(FeatureFlags.TRUSTPILOT, false);

  const [showMenu, setShowMenu] = useState(false);

  const handleMenuClick = (trackEventName) => {
    if (typeof trackEventName === 'string') {
      Tracking.trackUserClicked({
        widget_name: trackEventName,
        from_view: 'Dashboard',
        view_type: 'Mobile',
      });
    }
    if (trackEventName.currentTarget.dataset.to) onForceLogin();
    setShowMenu(false);
  };

  useEffect(() => {
    document.body.classList[showMenu ? 'add' : 'remove']('__body-fixed-mobile');
  }, [showMenu]);

  const renderPublicMenu = () => {
    let topNavItems;
    if (isPublicProfilePage && trueprofileEnabled) {
      topNavItems = (
        <>
          <a
            className="mobile-nav-item"
            href={staticPage('/truprofile')}
            target="_blank"
            rel="noreferrer"
          >
            TruProfile
          </a>
          <a className="mobile-nav-item" href="/profile-builder">
            Try it now
          </a>
        </>
      );
    } else if (isResumeBuilderPage || isProfileBuilderPage) {
      topNavItems = (
        <>
          <a
            className="mobile-nav-item"
            href={staticPage('/resume')}
            target="_blank"
            onClick={handleMenuClick}
            rel="noreferrer"
          >
            Resume
          </a>

          <a
            className="mobile-nav-item"
            href={staticPage('/pricing')}
            target="_blank"
            onClick={handleMenuClick}
            rel="noreferrer"
          >
            Pricing
          </a>
        </>
      );
    } else {
      topNavItems = (
        <>
          {trueprofileEnabled && (
            <a
              className="mobile-nav-item"
              href={staticPage('/truprofile')}
              target="_blank"
              rel="noreferrer"
            >
              TruProfile <span className="new-badge">NEW</span>
            </a>
          )}

          <a
            className="mobile-nav-item"
            href={staticPage('/resume')}
            target="_blank"
            onClick={handleMenuClick}
            rel="noreferrer"
          >
            Resume
          </a>

          <a
            className="mobile-nav-item"
            href={staticPage('/pricing')}
            target="_blank"
            onClick={handleMenuClick}
            rel="noreferrer"
          >
            Pricing
          </a>
          <a className="mobile-nav-item" href={'/resume-builder'}>
            Try it now
          </a>
        </>
      );
    }

    if (hideLinks) {
      topNavItems = <></>;
    }

    return (
      <>
        <div className="top-items">{topNavItems}</div>

        <div className="bottom-items">
          <div className="section-item">My account</div>
          <Link
            className="mobile-nav-item"
            to="/login"
            data-to="login"
            onClick={handleMenuClick}
          >
            Log In
          </Link>
        </div>
      </>
    );
  };

  const renderPrivateMenu = () => {
    if (superUser) {
      return (
        <div className="top-items">
          <Link className="mobile-nav-item" to="/logout">
            Log out
          </Link>
        </div>
      );
    }

    return (
      <>
        <div className="top-items">
          <>
            <div className="section-item">My Trudy dashboard</div>

            <Link
              className={cn('mobile-nav-item', {
                active: checkIsSelectedPath('/dashboard'),
              })}
              to={
                trueprofileEnabled ? '/dashboard' : '/dashboard?tab=documents'
              }
              onClick={handleMenuClick}
            >
              {trueprofileEnabled && checkIsSelectedPath('/dashboard') ? (
                <HomeIcon />
              ) : (
                <HomeIconOutline />
              )}
              Home
            </Link>

            {trueprofileEnabled ? (
              <Link
                className={cn('mobile-nav-item', {
                  active: checkIsSelectedPath('/profile'),
                })}
                to="/profile"
                onClick={() => handleMenuClick('Profile')}
              >
                <img
                  src={currentUser?.avatar_url || '/missing-profile-avatar.svg'}
                  alt="Users avatar"
                  className="avatar"
                />
                TruProfile
                <span className="new-badge">NEW</span>
              </Link>
            ) : (
              fakeDoorEnabled && (
                <span
                  className="mobile-nav-item"
                  onClick={() => {
                    setOpenSplashScreen(true);
                    setShowMenu(false);
                  }}
                >
                  TruProfile
                  <span className="new-badge">NEW</span>
                </span>
              )
            )}

            <Link
              className={cn('mobile-nav-item', {
                active: checkIsSelectedPath('/assessment-results'),
              })}
              to={
                trueprofileEnabled
                  ? '/assessment-results'
                  : '/dashboard?tab=assessment'
              }
              onClick={handleMenuClick}
            >
              {trueprofileEnabled && <ClipboardGraphIcon />}
              Assessment results
            </Link>

            {trueprofileEnabled && (
              <CreateDocumentButton
                showIcon
                onClick={() => setShowMenu(false)}
              />
            )}

            {showTrustPilot && <TrustPilot />}

            {!trueprofileEnabled && (
              <Link
                className="mobile-nav-item"
                to="/dashboard?tab=templates"
                onClick={handleMenuClick}
              >
                Templates
              </Link>
            )}
          </>
        </div>

        <div className="bottom-items">
          <div className="section-item">Account settings</div>
          <Link
            className={cn('mobile-nav-item', {
              active: checkIsSelectedPath('/account'),
            })}
            to={trueprofileEnabled ? '/account' : '/settings?tab=account'}
            onClick={handleMenuClick}
          >
            Account
          </Link>
          <Link
            className={cn('mobile-nav-item', {
              active: checkIsSelectedPath('/subscription'),
            })}
            to={
              trueprofileEnabled
                ? '/subscription'
                : '/settings?tab=subscription'
            }
            onClick={handleMenuClick}
          >
            Plan
          </Link>
          <Link
            className={cn('mobile-nav-item', {
              active: checkIsSelectedPath('/payments'),
            })}
            to={trueprofileEnabled ? '/payments' : '/settings?tab=payment'}
            onClick={handleMenuClick}
          >
            Payment
          </Link>
          {showReferralUI && currentUser?.referrer_count < 3 && (
            <Link
              className={cn('mobile-nav-item', {
                active: checkIsSelectedPath('/invite'),
              })}
              to="/invite"
              onClick={handleMenuClick}
            >
              Invite friends
            </Link>
          )}
          <Link
            className="mobile-nav-item"
            to="/feedback"
            onClick={handleMenuClick}
          >
            Submit Feedback
          </Link>
          <Link
            className="mobile-nav-item"
            to="/logout"
            onClick={handleMenuClick}
          >
            Logout
          </Link>
        </div>
      </>
    );
  };

  return (
    <div className="__mobile-header">
      <div className="mobile-header-title">
        <div className="flex">
          <TrudyLogo />
          {superUser && isAdminPage && <p className="admin-label">ADMIN</p>}
        </div>
        {!superUser && children}

        <div
          className="hamburger-menu"
          role="button"
          onClick={() => {
            setShowMenu(true);
            setOpenSplashScreen(false);
          }}
        >
          <div className="lines" />
          <div className="lines" />
          <div className="lines" />
        </div>
      </div>

      {showMenu && (
        <div className="drawer">
          <div className="drawer-header">
            <TrudyLogo />
            <button
              className="close-snippet"
              onClick={() => setShowMenu(false)}
            >
              ✕
            </button>
          </div>

          <div className="drawer-items">
            {!currentUser?.registered
              ? renderPublicMenu()
              : renderPrivateMenu()}
          </div>
        </div>
      )}
      {openSplashScreen && (
        <SplashScreen
          onCloseSplashScreen={(event) => {
            setOpenSplashScreen(false);
            if (event !== 'AVOID_LOG') {
              Tracking.trackUserClicked({
                widget_name: 'trueprofile-splashscreen-close',
              });
            }
          }}
        />
      )}
    </div>
  );
}
