import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import './KoiButton.scss';

export default function KoiButton({
  className,
  icon = null,
  loading,
  disabled,
  children,
  ...props
}) {
  const buttonProps = {
    ...props,
    disabled: disabled || loading,
  };

  return (
    <Button
      className={classNames(
        '__koi-button',
        { '__koi-button-icon': icon },
        className,
      )}
      {...buttonProps}
    >
      {icon ? (
        <>
          {icon}
          <span>{children}</span>
        </>
      ) : loading ? (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span class="__koi-button-loading">{children}</span>
        </>
      ) : (
        children
      )}
    </Button>
  );
}
