import { ReactComponent as CelebrationIcon } from '../../assets/icons/koi/celebration.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/koi/paywall-close-icon.svg';
import KoiButton from '../../layout/koi/KoiButton';
import KoiTitle from '../../layout/koi/KoiTitle';
import { addDaysToDate } from '../../utils/dates';
import { formatFromIso } from '../../utils/util';

export default function SuccessfulPurchase({
  selectedPlan,
  subscription,
  subscriptionType,
  onHide,
  email,
}) {
  return (
    <div>
      <div
        className="close-icon"
        onClick={() => {
          onHide(true);
        }}
      >
        <CloseIcon />
      </div>
      <div className="purchase-content" data-cy="successful-purchase">
        <div className="purchase-icon-wrapper">
          <CelebrationIcon />
        </div>
        <KoiTitle className="purchase-title">
          Your resume has been unlocked! Download it now.
        </KoiTitle>
        <p className="purchase-subtitle">
          We’ve emailed your receipt to{' '}
          <span className="receipt-email">{email}</span>.
        </p>

        <div className="purchase-details">
          <p className="purchase-details-subtitle">Your plan</p>
          <div className="purchase-details-label">{selectedPlan.title}</div>

          {subscriptionType && (
            <>
              <p className="purchase-details-subtitle">
                Your next billing date
              </p>

              <div>
                {selectedPlan.fullPrice} on{' '}
                {selectedPlan.free_trial
                  ? addDaysToDate(7)
                  : formatFromIso(subscription.cutoff)}
              </div>
            </>
          )}
        </div>

        <div className="done-btn-wrapper">
          <KoiButton
            onClick={() => {
              onHide(true);
            }}
            variant="primary"
          >
            Done
          </KoiButton>
        </div>
      </div>
    </div>
  );
}
