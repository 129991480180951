import { useMemo, useState } from 'react';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import ReCAPTCHA from 'react-google-recaptcha';

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';

import { ReactComponent as ArrowLeft } from '../../assets/icons/koi/arrow-left.svg';
import { ReactComponent as CheckmarkIcon } from '../../assets/icons/koi/checkmark.svg';
import { ReactComponent as ChevronDown } from '../../assets/icons/koi/chevron-down.svg';
import { ReactComponent as InvalidIcon } from '../../assets/icons/koi/invalid-icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/koi/paywall-close-icon.svg';
import { ReactComponent as ValidIcon } from '../../assets/icons/koi/valid-icon.svg';
import { ReactComponent as PoweredByStripe } from '../../assets/pricing/powered_stripe_black.svg';
import TruepathInput from '../../common/TruepathInput/TruepathInput';
import { FeatureFlags } from '../../constants/featureFlags';
import { STRIPE_STYLE } from '../../constants/payment';
import useFeatureFlag from '../../hooks/useFeatureFlag';
import usePaywallDiscountModal from '../../hooks/usePaywallDiscountModal';
import usePaywallMonthDiscount from '../../hooks/usePaywallMonthDiscount';
import usePricingExperimentFlag from '../../hooks/usePricingExperimentFlag';
import { useWindowSize } from '../../hooks/useWindowSize';
import KoiButton from '../../layout/koi/KoiButton';
import { addDaysToDate } from '../../utils/dates';
import { getDiscountedPrice } from '../../utils/formatter';
import { formatDate } from '../../utils/util';

import { COUNTRIES, MONTHLY_TIERS } from './constants';

export function SelectedPlanDetails({
  coupon,
  currentUser,
  features,
  selectedPlan,
}) {
  const [displayDetails, setDisplayDetails] = useState(false);
  const { PRICING_EXPERIMENT_ENABLED } = usePricingExperimentFlag();
  const { isMobile } = useWindowSize();
  const { utmDiscount, showPaywallDiscountModal } = usePaywallDiscountModal();
  const discountDisclaimer = `You will automatically be charged the subscription
  fee of ${getDiscountedPrice(selectedPlan.price, utmDiscount)} today, and then
  charged $24.90 every month until you cancel.`;
  const hideFreeTrial = selectedPlan.oneTimePayment || !selectedPlan.free_trial;
  const currentMonthData = useMemo(() => {
    const plans = Object.values(MONTHLY_TIERS);
    const plan = plans.find((plan) => plan.id === selectedPlan.id);
    return plan || MONTHLY_TIERS['pay-as-you-go'];
  }, [selectedPlan?.id]);

  const isSubscribed = currentUser?.subscribed;

  const cutOffDate = formatDate(currentUser?.subscription?.cutoff, 'LLLL d');

  const { showPaywallMonthDiscount } = usePaywallMonthDiscount();
  const useMonthDiscount = !isSubscribed && showPaywallMonthDiscount;

  const foreverCoupon = coupon?.duration === 'forever';

  const renderPrice = (price) => {
    if (coupon.percent_off) {
      return getDiscountedPrice(price, coupon.percent_off);
    }

    return price;
  };

  const renderControlVariant = () => (
    <>
      <div className="top-content">
        <div className="selected-plan-details include-icons">
          <p className="selected-plan-label">Selected plan</p>
          <div className="selected-plan-controls">
            <p className="main-title">{selectedPlan.title}</p>
            <ChevronDown
              className={classNames({ reversed: displayDetails })}
              onClick={() => {
                setDisplayDetails(!displayDetails);
              }}
            />
          </div>
          <p className="main-subtitle">{selectedPlan.info}</p>

          <div
            className={classNames('selected-plan-features', {
              hidden: !displayDetails,
            })}
          >
            <p>Includes:</p>
            {features.map((feature) => (
              <div className="plan-feature-item" key={feature}>
                <CheckmarkIcon className="checkmark-icon" />
                <p>{feature}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className={classNames('footer-info', {
          'footer-info-variant-mobile': PRICING_EXPERIMENT_ENABLED && isMobile,
        })}
      >
        {selectedPlan.free_trial && (
          <div className="flex-item">
            <p className="main-title">Due today</p>{' '}
            <p className="main-title green-label">$0</p>
          </div>
        )}
        <div className="flex-item">
          {selectedPlan.free_trial ? (
            <>
              <p>Billed on {addDaysToDate(7)}</p>
              <div className="display-flex">
                {coupon.percent_off && (
                  <p className="billed-on-price coupon-discount">
                    {selectedPlan.fullPrice}
                  </p>
                )}
                <p className="billed-on-price">
                  {coupon.percent_off
                    ? getDiscountedPrice(
                        selectedPlan.fullPrice,
                        coupon.percent_off,
                      )
                    : selectedPlan.fullPrice}
                </p>
              </div>
            </>
          ) : (
            <>
              <p className="main-title">Due today</p>
              <div className="display-flex">
                {coupon.percent_off && (
                  <p className="main-title coupon-discount">
                    {selectedPlan.fullPrice}
                  </p>
                )}

                <p className="main-title green-label">
                  {coupon.percent_off
                    ? getDiscountedPrice(
                        selectedPlan.fullPrice,
                        coupon.percent_off,
                      )
                    : selectedPlan.fullPrice}
                </p>
              </div>
            </>
          )}
        </div>

        <p className="disclaimer">
          {showPaywallDiscountModal
            ? discountDisclaimer
            : selectedPlan.disclaimer}
        </p>
      </div>
    </>
  );

  const renderExperimentVariant = () => (
    <>
      <div className="top-content">
        <div className="selected-plan-details selected-plan-details-variant">
          <p className="selected-plan-label-variant">Order summary</p>
          <div className="pricing-plan">
            <div className="plan-header">
              <h2 className="plan-header-title">{currentMonthData.title}</h2>
              <span className="price">
                {currentMonthData.price}
                {currentMonthData.onePayment ? '/doc' : '/mo'}
              </span>
            </div>
            {!hideFreeTrial && (
              <button className="free-trial-btn">7-day free trial</button>
            )}

            <hr className="payment-divider" />
            <div className="total-due">
              <p className="plan-header-title">Total due today</p>
              <span className="price-today display-flex">
                {coupon.percent_off && hideFreeTrial && (
                  <p className="coupon-discount">
                    {hideFreeTrial ? selectedPlan.fullPrice : '$0.00'}
                  </p>
                )}
                {hideFreeTrial ? renderPrice(selectedPlan.fullPrice) : '$0.00'}
              </span>
            </div>

            {currentMonthData.onePayment && foreverCoupon && (
              <>
                <hr className="payment-divider" />
                <div className="free-trial-terms">
                  <h3 className="plan-header-title">Terms</h3>
                  <ul>
                    <li>{`${coupon.percent_off}% discount on purchase`}</li>
                  </ul>
                </div>
              </>
            )}

            {!hideFreeTrial && (
              <>
                <div className="billing-info">
                  <p className="mb-0">
                    Charged on {addDaysToDate(7)}, after trial ends
                  </p>
                  <span className="future-price display-flex">
                    {coupon.percent_off && (
                      <p className="coupon-discount">
                        {selectedPlan.fullPrice}
                      </p>
                    )}
                    {renderPrice(currentMonthData.fullPrice)}
                  </span>
                </div>

                <hr className="payment-divider" />
                <div className="free-trial-terms">
                  <h3 className="plan-header-title">Terms</h3>
                  <ul>
                    {foreverCoupon && (
                      <li>{`${coupon.percent_off}% discount on purchase`}</li>
                    )}
                    <li>Billing automatically starts after free trial ends</li>
                    <li>
                      Cancel anytime before {addDaysToDate(7, null, 'LLLL d')}{' '}
                      to avoid getting charged
                    </li>
                    <li>
                      {selectedPlan.autoRenewMessage(
                        cutOffDate,
                        foreverCoupon
                          ? getDiscountedPrice(
                              selectedPlan.fullPrice,
                              coupon.percent_off,
                            )
                          : selectedPlan.fullPrice,
                      )}
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className={classNames('footer-info', {
          'footer-info-variant-mobile': PRICING_EXPERIMENT_ENABLED && isMobile,
        })}
      >
        {!isMobile && !foreverCoupon && (
          <p className="disclaimer">
            {showPaywallDiscountModal
              ? discountDisclaimer
              : selectedPlan.disclaimer}
          </p>
        )}
      </div>
    </>
  );

  return PRICING_EXPERIMENT_ENABLED && !useMonthDiscount
    ? renderExperimentVariant()
    : renderControlVariant();
}

export function Payment(props) {
  const currentUser = props.currentUser;
  const subscribedWithPaymentMethod =
    !!currentUser?.payment_method_brand && currentUser.subscribed;
  const [showPaymentDetails, setShowPaymentDetails] = useState(
    subscribedWithPaymentMethod,
  );

  const paymentDeatilsProps = {
    ...props,
    onChangePaymentMethod: () => setShowPaymentDetails(false),
  };

  return showPaymentDetails ? (
    <PaymentDetails {...paymentDeatilsProps} />
  ) : (
    <PaymentForm {...props} />
  );
}

export function PaymentDetails(props) {
  const { isMobile } = useWindowSize();
  const { PRICING_EXPERIMENT_ENABLED } = usePricingExperimentFlag();
  const { currentUser, onBack, onHide, onChangePaymentMethod } = props;
  const { payment_method_brand, payment_method_last4 } = currentUser;
  const paddedLast4 = String(payment_method_last4).padStart(4, '0');

  return (
    <>
      <div className="close-icon" onClick={onHide}>
        <CloseIcon />
      </div>
      <div className="arrow-icon-container" onClick={onBack}>
        <ArrowLeft />
      </div>

      <div
        className={classNames(
          'choose-plan-label show',
          'choose-plan-label-variant',
        )}
      >
        Payment details
      </div>

      <div className="paywall-form">
        <div className="credit-card-info-container">
          <div className="credit-card-info">
            <div className="credit-card-info-company">
              {payment_method_brand}
            </div>
            <div>****{paddedLast4}</div>
            <div
              className="credit-card-info-link"
              onClick={onChangePaymentMethod}
            >
              Change
            </div>
          </div>
        </div>

        <div className="bottom-container"></div>
        <div
          className={
            PRICING_EXPERIMENT_ENABLED && isMobile
              ? 'continue-btn-wrapper-variant'
              : 'continue-btn-wrapper'
          }
        >
          <KoiButton
            type="submit"
            className={
              PRICING_EXPERIMENT_ENABLED && isMobile
                ? 'continue-btn-variant'
                : 'continue-btn'
            }
            variant="primary"
            onClick={onHide}
          >
            Confirm
          </KoiButton>
        </div>
      </div>
    </>
  );
}

export function PaymentForm({
  currentUser,
  error,
  fetchPromoCode,
  formData,
  processingPayment,
  promoCodeStatus,
  promoCodeValue,
  purchaseSuccess,
  recaptchaRef,
  reCaptchaSiteKey,
  selectedPlan,
  validated,
  handlePromoCodeInput,
  handleSubmit,
  paymentRequest,
  onChange,
  onHide,
  onBack,
  showPromoCode = true,
}) {
  const { PRICING_EXPERIMENT_ENABLED } = usePricingExperimentFlag();
  const { isMobile } = useWindowSize();

  const { showPaywallMonthDiscount } = usePaywallMonthDiscount();
  const useMonthDiscount = !currentUser?.subscribed && showPaywallMonthDiscount;

  const enabled = useFeatureFlag(FeatureFlags.UPDATE_CHECKOUT, 'Control');
  const updateCheckoutEnabled = enabled?.toLowerCase()?.includes('treatment');

  const showZipCode = formData?.country === 'US';

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onChange({ [name]: value });
  };

  const renderCountryOptions = () => {
    const options = COUNTRIES.map((country) => (
      <option key={country.code} value={country.code}>
        {country.name}
      </option>
    ));
    options.unshift(
      <option key="select" value="">
        Select country
      </option>,
    );
    return options;
  };

  return (
    <>
      <div
        className="close-icon"
        onClick={() => {
          onHide();
        }}
      >
        <CloseIcon />
      </div>
      <div className="arrow-icon-container" onClick={onBack}>
        <ArrowLeft />
      </div>

      <div
        className={classNames('choose-plan-label show', {
          'choose-plan-label-variant':
            PRICING_EXPERIMENT_ENABLED && !useMonthDiscount,
        })}
      >
        {PRICING_EXPERIMENT_ENABLED && !useMonthDiscount
          ? 'Payment details'
          : 'Payment methods'}
      </div>

      {paymentRequest && (
        <div className="third-party-payments">
          <PaymentRequestButtonElement options={{ paymentRequest }} />
          <div className="separator">
            <span>Or pay with card</span>
          </div>
        </div>
      )}

      <Form
        className="paywall-form"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <div
          className={classNames('top-content', {
            'updated-height': updateCheckoutEnabled,
          })}
        >
          {updateCheckoutEnabled && (
            <div className="card-user-name">
              <TruepathInput
                errorMessage="Please enter a name."
                hideRequiredMark
                label="Name on card"
                name="name"
                required
                value={formData?.name}
                onChange={handleInputChange}
              />
            </div>
          )}
          <div className="card-number">
            <div className="stripe-label">Card number</div>
            <div className="input">
              <CardNumberElement
                options={{ style: STRIPE_STYLE, showIcon: true }}
              />
            </div>
          </div>

          <div className="date-cvc-container">
            <div
              className={classNames('expiry-date', {
                'updated-field': updateCheckoutEnabled,
              })}
            >
              <div className="stripe-label">Expiration date</div>
              <div className="input">
                <CardExpiryElement options={{ style: STRIPE_STYLE }} />
              </div>
            </div>
            <div
              className={classNames('cvc', {
                'updated-field': updateCheckoutEnabled,
              })}
            >
              <div className="stripe-label">Security code</div>
              <div className="input">
                <CardCvcElement options={{ style: STRIPE_STYLE }} />
              </div>
            </div>
          </div>

          {updateCheckoutEnabled && (
            <div className="address-container">
              <div className="updated-field">
                <TruepathInput
                  errorMessage="Please select the country."
                  hideRequiredMark
                  label="Country"
                  name="country"
                  required
                  type="select"
                  value={formData?.country}
                  onChange={handleInputChange}
                >
                  {renderCountryOptions()}
                </TruepathInput>
              </div>
              {showZipCode ? (
                <div className="updated-field">
                  <TruepathInput
                    errorMessage="Must be a 5 digit number."
                    hideRequiredMark
                    label="Zip code"
                    name="zipCode"
                    pattern={'\\d{5,5}?'}
                    required
                    value={formData?.zipCode}
                    onChange={handleInputChange}
                  />
                </div>
              ) : (
                <div className="divider" />
              )}
            </div>
          )}
          <a
            className="powered-by-stripe"
            rel="__noreferrer"
            href="https://stripe.com"
          >
            <PoweredByStripe />
          </a>

          {showPromoCode && (
            <>
              <hr />

              <div className="input promo-code-wrapper">
                <div className="promo-code-input">
                  {PRICING_EXPERIMENT_ENABLED && (
                    <p className="promo-code-label">Promo code</p>
                  )}
                  <TruepathInput
                    label={
                      !PRICING_EXPERIMENT_ENABLED ? 'Enter promo code' : ''
                    }
                    className={
                      promoCodeStatus === 'ERROR' ? 'invalid-code-input' : ''
                    }
                    value={promoCodeValue}
                    onChange={handlePromoCodeInput}
                  />

                  <div
                    className={classNames('promo-code-icon', {
                      'promo-code-icon-variant': PRICING_EXPERIMENT_ENABLED,
                    })}
                  >
                    {promoCodeStatus === 'SUCCESS' && <ValidIcon />}
                    {promoCodeStatus === 'ERROR' && <InvalidIcon />}
                  </div>
                </div>

                <KoiButton
                  className="apply-btn ml-10"
                  variant="secondary"
                  onClick={fetchPromoCode}
                  disabled={!promoCodeValue}
                >
                  {promoCodeStatus === 'LOADING' ? <Spinner /> : 'Apply'}
                </KoiButton>
              </div>
              {promoCodeStatus === 'ERROR' && (
                <p className="invalid-code">Invalid code.</p>
              )}
            </>
          )}
        </div>

        <div className="bottom-container">
          <div>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={reCaptchaSiteKey}
              size="invisible"
            />
          </div>
        </div>
        <div
          className={
            PRICING_EXPERIMENT_ENABLED && isMobile
              ? 'continue-btn-wrapper-variant'
              : 'continue-btn-wrapper'
          }
        >
          <KoiButton
            type="submit"
            className={
              PRICING_EXPERIMENT_ENABLED && isMobile
                ? 'continue-btn-variant'
                : 'continue-btn'
            }
            variant="primary"
            disabled={!selectedPlan.id || purchaseSuccess || error}
          >
            {purchaseSuccess ? (
              'Payment successful!'
            ) : processingPayment ? (
              <Spinner />
            ) : (
              'Confirm'
            )}
          </KoiButton>
        </div>
      </Form>
    </>
  );
}
