import React, { useEffect, useRef } from 'react';

import { useWindowSize } from '../../hooks/useWindowSize';
import { getDomain, getEnvSetting } from '../../utils/envSettings';

import './TrustPilot.scss';

const TrustPilot = ({ useWidget = false }) => {
  const ref = useRef(null);
  const { isMobile } = useWindowSize();

  const getTrustPilotDomain = () => {
    const domain = getDomain();
    if (domain !== '.mytrudy.com') {
      return 'mytrudyqa.com';
    } else {
      return domain.slice(1);
    }
  };

  useEffect(() => {
    if (window.Trustpilot && useWidget) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [useWidget]);

  if (useWidget) {
    return (
      <div
        ref={ref}
        className="trustpilot-widget"
        data-locale="en-US"
        data-template-id={getEnvSetting('TRUST_PILOT_TEMPLATE_ID')}
        data-businessunit-id={getEnvSetting('TRUST_PILOT_BUSINESS_UNIT_ID')}
        data-style-height="52px"
        data-style-width="100%"
      >
        <a
          href={`https://www.trustpilot.com/review/${getTrustPilotDomain()}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
      </div>
    );
  }

  if (isMobile) {
    return (
      <div className="trustpilot-container">
        <a
          target="_blank"
          href={`https://www.trustpilot.com/review/${getTrustPilotDomain()}`}
          className="trustpilot-button"
          rel="noreferrer"
        >
          Review us on
          <svg
            className="trustpilot-star"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
              fill="currentColor"
            />
          </svg>
          Trustpilot
        </a>
      </div>
    );
  }

  return (
    <div className="trustpilot-container">
      <h2 className="trustpilot-heading">Tell us what you think.</h2>
      <a
        target="_blank"
        href={`https://www.trustpilot.com/review/${getTrustPilotDomain()}`}
        className="trustpilot-button"
        rel="noreferrer"
      >
        Review us on
        <svg
          className="trustpilot-star"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
            fill="currentColor"
          />
        </svg>
        Trustpilot
      </a>
    </div>
  );
};
export default TrustPilot;
