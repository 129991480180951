import axios from 'axios';
import Cookies from 'js-cookie';

import Bugsnag from '@bugsnag/js';

import { getEnvSetting } from './envSettings';

// const apiURL = getEnvSetting('API_URL');
const apiURL =
  window.location.hostname === 'app.mytrudy.com'
    ? getEnvSetting('NEW_API_URL')
    : getEnvSetting('API_URL');

export let api = axios.create({
  baseURL: apiURL,
});

api.interceptors.request.use((config) => {
  const token = Cookies.get('talent__api_access_token');

  // Check if there is a token and if the existing Authorization header is invalid
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
});

export const retryableHttpRequest = async (
  url,
  attemptNumber = 0,
  maxRetries = 3,
  method = 'GET',
  data,
) => {
  try {
    const response = await api({
      method,
      url,
      data,
    });
    return response.data;
  } catch (error) {
    if (attemptNumber > maxRetries) {
      Bugsnag.notify(
        new Error(
          `Max number of retries reached. Error: ${error.message} for ${url} endpoint`,
        ),
      );
      throw new Error(error);
    }
    setTimeout(() => {
      retryableHttpRequest(url, attemptNumber + 1, maxRetries, method, data);
    }, 500);
  }
};
