import './PublicLayout.scss';

import { Outlet } from 'react-router-dom';

import ScrollToTop from '../utils/ScrollToTop';
import NavigationHeader from './navigation/NavigationHeader';

export default function PublicLayout() {
  return (
    <div className="__public-layout">
      <NavigationHeader />
      <ScrollToTop />
      <main className="main-content">
        <Outlet />
      </main>
    </div>
  );
}
