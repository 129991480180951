import { useGoogleLogin } from '@react-oauth/google';

import { ReactComponent as GoogleIcon } from '../../assets/icons/koi/google-icon.svg';
import KoiButton from '../../layout/koi/KoiButton';

import './SSOButtons.scss';

export default function SSOButtons({
  googleButtonText = 'Log in with Google',
  disabled = false,
  onSuccessGoogle,
  onFailure,
}) {
  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => onSuccessGoogle(codeResponse),
    onFailure: () => onFailure('Google'),
    flow: 'auth-code',
  });

  return (
    <div className="__sso">
      <KoiButton
        className="social-btn"
        icon={<GoogleIcon />}
        variant="secondary"
        disabled={disabled}
        onClick={googleLogin}
      >
        {googleButtonText}
      </KoiButton>
    </div>
  );
}
