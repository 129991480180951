import './PrivateLayout.scss';

import cn from 'classnames';
import { useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as ChevronDown } from '../assets/icons/koi/chevron-down.svg';
import { ReactComponent as ClipboardGraphIcon } from '../assets/icons/koi/clipboard-graph-icon.svg';
import { ReactComponent as HomeIcon } from '../assets/icons/koi/home-icon.svg';
import { ReactComponent as HomeIconOutline } from '../assets/icons/koi/home-icon-outline.svg';
import { ReactComponent as LightBulbIcon } from '../assets/icons/koi/lightbulb-white.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/koi/settings-icon.svg';
import TrustPilot from '../common/TrustPilot/TrustPilot';
import { FeatureFlags } from '../constants/featureFlags';
import useFeatureFlag from '../hooks/useFeatureFlag';
import useOnboarding from '../hooks/useOnboarding';
import { useWindowSize } from '../hooks/useWindowSize';
import CreateDocumentButton from '../routes/Dashboard/CreateDocumentButton';
import { useAppContext } from '../store/AppContext';
import Tracking from '../utils/tracking';
import NavigationHeader from './navigation/NavigationHeader';
import TrudyLogo from './navigation/TrudyLogo';

const APP_NAV_ITEMS = [
  {
    label: 'Home',
    icon: HomeIconOutline,
    selectedIcon: HomeIcon,
    path: '/dashboard',
  },
  {
    label: 'TruProfile',
    path: '/profile',
  },
  {
    label: 'Assessment results',
    icon: ClipboardGraphIcon,
    selectedIcon: ClipboardGraphIcon,
    path: '/assessment-results',
  },
];

const SETTINGS_NAV_ITEMS = [
  {
    label: 'Account',
    path: '/account',
  },
  {
    label: 'Plan',
    path: '/subscription',
  },
  {
    label: 'Payment',
    path: '/payments',
  },
  {
    label: 'Submit Feedback',
    path: '/feedback',
  },
  {
    label: 'Log out',
    path: '/logout',
  },
];

export default function PrivateLayout() {
  const [expandSettingsMenu, setExpandSettingsMenu] = useState(false);
  const { appState } = useAppContext();
  const navigate = useNavigate();

  const { currentUser } = appState;

  const location = useLocation();
  const { pathname } = location;

  const { isTablet } = useWindowSize();

  const { ready } = useOnboarding('dashboard');
  const showTrustPilot = useFeatureFlag(FeatureFlags.TRUSTPILOT, false);

  const handleSettingMenuClick = () => {
    setExpandSettingsMenu(!expandSettingsMenu);
  };

  const checkIsSelectedPath = (path) => path === pathname;

  const trackNavClick = (navItem) => {
    const TRACKEABLE_NAV_ITEMS_PATHS = ['/profile'];

    if (TRACKEABLE_NAV_ITEMS_PATHS.includes(navItem.path)) {
      Tracking.trackUserClicked({
        widget_name: navItem.label,
        from_view: 'Dashboard',
        view_type: 'Desktop',
      });
    }
  };

  if (!ready) {
    return;
  }

  return (
    <div className="__private-layout">
      {isTablet && <NavigationHeader />}

      {!isTablet && (
        <aside className="side-nav">
          <section className="section-top">
            <div className="logo">
              <TrudyLogo />
            </div>

            <CreateDocumentButton showIcon />

            <nav className="app-nav">
              <ul>
                {APP_NAV_ITEMS.map((item) => (
                  <li
                    key={`app-nav-item-${item.label}`}
                    className={cn({ selected: checkIsSelectedPath(item.path) })}
                  >
                    <Link onClick={() => trackNavClick(item)} to={item.path}>
                      {item?.icon && (
                        <>
                          {checkIsSelectedPath(item.path) ? (
                            <item.selectedIcon />
                          ) : (
                            <item.icon />
                          )}
                        </>
                      )}

                      {item.path === '/profile' && (
                        <img
                          src={
                            currentUser?.avatar_url ||
                            '/missing-profile-avatar.svg'
                          }
                          alt="Users avatar"
                          className="avatar"
                        />
                      )}

                      {item.label}

                      {item.path === '/profile' && (
                        <span className="new-badge">NEW</span>
                      )}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </section>

          <section className="section-bottom">
            {showTrustPilot && <TrustPilot />}

            <nav className="settings-nav">
              <div
                className="settings-menu"
                role="button"
                onClick={handleSettingMenuClick}
              >
                <div className="left">
                  <SettingsIcon className="settings-icon" />
                  Account settings
                </div>

                <div className="right">
                  <ChevronDown
                    className={cn('chevron-down-icon', {
                      expanded: expandSettingsMenu,
                    })}
                  />
                </div>
              </div>

              <div
                className={cn('settings-menu-items', {
                  expanded: expandSettingsMenu,
                })}
              >
                <ul>
                  {SETTINGS_NAV_ITEMS.map((item) => (
                    <li
                      key={`settings-nav-item-${item.label}`}
                      className={cn({
                        selected: checkIsSelectedPath(item.path),
                      })}
                    >
                      <Link to={item.path}>{item.label}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </nav>
          </section>
        </aside>
      )}

      <main className="main-content">
        <Outlet />
        <button
          className="floating-button"
          onClick={() => navigate('/feedback')}
        >
          <LightBulbIcon />
          <span>Submit Feedback</span>
        </button>
      </main>
    </div>
  );
}
